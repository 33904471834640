import {
  ApolloLink,
  createHttpLink,
  FetchResult,
  fromPromise,
  InMemoryCache,
  Observable,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { Cookies } from "react-cookie";
import { onError } from "@apollo/client/link/error";
import * as jose from "jose";
import {
  createApolloLoaderHandler,
  ApolloClient,
} from "@apollo/client-integration-react-router";
import * as Sentry from "@sentry/react";

/**
 * GraphQL Client Configuration
 *
 * This file configures Apollo Client with WebSocket support for subscriptions.
 *
 * Reconnection Strategy:
 * - WebSocket connections can fail when the computer sleeps or the network changes
 * - We use built-in retry mechanism with infinite attempts and exponential backoff
 * - Active subscriptions will automatically resubscribe after reconnection
 */

const cookies = new Cookies();

let graphqlUrl = "";
let revision = "";
if (typeof window === "undefined") {
  graphqlUrl = process.env.GRAPHQL_API as string;
  revision = process.env.REVISION as string;
} else {
  graphqlUrl = webConfig.graphqlAPI;
  revision = webConfig.revision;
}

const httpLink = createHttpLink({
  uri: graphqlUrl,
});

let endpointLink = httpLink;
if (typeof window !== "undefined") {
  const wsLink = new GraphQLWsLink(
    createClient({
      url: graphqlUrl.replaceAll("https:", "wss:"),
      keepAlive: 30000,
      retryAttempts: Infinity,
      retryWait: (retries) => {
        // Fixed 1-second delay between reconnection attempts
        console.log("[WS] Retrying connection... (attempt", retries + 1, ")");
        return new Promise((resolve) => setTimeout(resolve, 1000));
      },
      connectionParams: () => {
        return refreshIfCloseToExpiration(cookies)
          .then((token) => ({
            authentication:
              "Bearer " + (token || cookies.get("x-ms-access-token") || ""),
          }))
          .catch((error) => {
            console.error("[WS] Error refreshing token:", error);
            Sentry.captureException(
              new Error(`[WS] Error refreshing token: ${error}`),
            );
            // This use is probably not authenticated, continue without auth.
            return {};
          });
      },
      on: {
        closed: (event) => {
          const closeEvent = event as CloseEvent;

          console.warn(
            `[WS] WebSocket closed. Code: ${closeEvent.code}, Reason: ${closeEvent.reason}`,
          );

          if (closeEvent.code === 1001) {
            console.warn(
              "[WS] WebSocket closed (1001 - Going Away). Ignoring.",
            );
            return;
          }

          if (closeEvent.code !== 1000) {
            console.error(
              `[WS] Abnormal closure detected. Code: ${closeEvent.code}`,
            );
            Sentry.captureException(
              new Error(`[WS] Abnormal Closure: Code ${closeEvent.code}`),
            );
          }
        },
        error: (event) => {
          const errorEvent = event as ErrorEvent;

          console.error("[WS] WebSocket error detected:", errorEvent);

          // Capture meaningful payload if data structure is unclear
          const eventDetails = JSON.stringify(errorEvent, null, 2);

          Sentry.captureException(
            new Error(`[WS] WebSocket Error: ${eventDetails}`),
          );
        },
        connecting: () => {
          console.log("[WS] Connecting to GraphQL WebSocket...");
        },
        connected: () => {
          console.log("[WS] Successfully connected to GraphQL WebSocket");
        },
      },
    }),
  );

  endpointLink = split(
    ({ query }) => {
      if (typeof window === "undefined") {
        return false;
      }
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink,
  );
}

const refreshTokenLink = new ApolloLink(
  (operation, forward): Observable<FetchResult> | null => {
    if (typeof window !== "undefined") {
      return fromPromise(
        refreshIfCloseToExpiration(cookies)
          .then((token) => {
            // Store the refreshed token in operation context
            operation.setContext(({ headers = {} }) => ({
              headers,
              refreshedToken: token,
            }));
          })
          .catch((error) => {
            // This use is probably not authenticated, continue without auth.
          }),
      ).flatMap(() => {
        return forward(operation);
      });
    }
    return forward(operation);
  },
);

const metadataHeadersLink = setContext((_, { headers }) => {
  let newHeaders = {
    ...headers,
    "x-nspr-app": "web",
    "x-nspr-app-version": revision,
  };
  return { headers: newHeaders };
});

const authLink = setContext((operation, context) => {
  if (typeof window === "undefined") {
    // In the backend, headers are added in the query directly, but let's add the app/revision
    return {
      headers: {
        ...context.headers,
      },
    };
  }

  // Get the refreshed token from context if available
  const token =
    context.refreshedToken || cookies.get("x-ms-access-token") || "";

  return {
    headers: {
      ...context.headers,
      Authorization: "Bearer " + token,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const directionalLink = new ApolloLink((operation, forward) => {
  return forward(operation);
}).split(
  (operation) => operation.getContext().skipAuth,
  metadataHeadersLink.concat(errorLink).concat(endpointLink),
  metadataHeadersLink
    .concat(errorLink)
    .concat(refreshTokenLink)
    .concat(authLink)
    .concat(endpointLink),
);
const cache: InMemoryCache = new InMemoryCache();
export const getServerClient = (request?: Request) => {
  return new ApolloClient({
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
      },
    },
    link: directionalLink,
  });
};
export const apolloLoader = createApolloLoaderHandler(getServerClient);

function refreshToken() {
  return fetch("/api/oauth/access_token")
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? await response.json() : null;

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }

      return Promise.resolve(data.AccessToken);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function refreshIfCloseToExpiration(cookies: Cookies) {
  let loggedIn = cookies.get("x-ms-logged-in");
  if (!loggedIn) {
    return;
  }
  let tokenValue = cookies.get("x-ms-access-token");
  if (!tokenValue) {
    return refreshToken();
  }
  const claims = jose.decodeJwt(tokenValue);
  if (
    claims?.exp &&
    new Date(claims.exp * 1000).getTime() > new Date().getTime() + 20000 // Token still live for 20s at least
  ) {
    return Promise.resolve();
  }
  // It has expired, let's refresh
  return refreshToken();
}
